import { ThemeOptions } from "@mui/material";

export const shadows: ThemeOptions["shadows"] = [
  "none",
  "0px 5px 10px #2E38621A",
  "0px 5px 10px -5px #2E38621A",
  "0px 5px 10px #2E38621A",
  "0px 3px 6px #00000029;", // header links text-shadow
  "0px 5px 10px #2E38621A",
  "0px 5px 10px #2E38621A",
  "0px 5px 10px #2E38621A",
  "0px 5px 10px #2E38621A",
  "0px 5px 10px #2E38621A",
  "0px 5px 10px #2E38621A",
  "0px 5px 10px #2E38621A",
  "0px 5px 10px #2E38621A",
  "0px 5px 10px #2E38621A",
  "0px 5px 10px #2E38621A",
  "0px 5px 10px #2E38621A",
  "0px 5px 10px #2E38621A",
  "0px 5px 10px #2E38621A",
  "0px 5px 10px #2E38621A",
  "0px 5px 10px #2E38621A",
  "0px 5px 10px #2E38621A",
  "0px 5px 10px #2E38621A",
  "0px 5px 10px #2E38621A",
  "0px 5px 10px #2E38621A",
  "0px 5px 10px #2E38621A",
];
