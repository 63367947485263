import { ThemeOptions } from "@mui/material";

// Example Palette Colors
export const colors = {
  backdropBackground: "rgba(244, 248, 255, 0.65)",
  brandBlue: {
    100: "#83C6FF",
    200: "#4C5479",
    300: "#39426A",
    400: "#2E3862",
  },
  brandGray: {
    100: "#f8f9fa",
    400: "#848AA3",
  },
  brandRed: "#E95B78",
  brandWhite: "#F4F8FF",
  error: "#E0447C",
  shadowHeader: {
    dark: "#D8E0F289",
    light: "#e9ecf5",
  },
  white: "#FFF",
};

export const palette: ThemeOptions["palette"] = {
  background: {
    dark: colors.brandBlue["300"],
    default: colors.brandWhite,
    light: colors.white,
    paper: colors.white,
  },
  error: {
    main: colors.error,
  },
  primary: {
    contrastText: colors.brandWhite,
    light: colors.brandBlue["100"],
    main: colors.brandBlue["400"],
  },
  secondary: {
    contrastText: colors.brandWhite,
    main: colors.brandRed,
  },
  site: {
    accordion: {
      expandIcon: colors.brandBlue["400"],
    },
    addons: {
      background: colors.white,
    },
    alertBar: colors.brandWhite,
    checkbox: {
      darkMode: colors.brandBlue["100"],
      lightMode: colors.brandBlue["400"],
    },
    contactForm: colors.shadowHeader.dark,
    footer: {
      background: colors.brandBlue["400"],
      bottom: colors.brandBlue["300"],
      crossSiteDropdown: colors.brandBlue["200"],
      horizontalDivider: colors.white,
      logoBg: colors.white,
      memberships: colors.brandWhite,
      verticalDivider: colors.white,
    },
    founderBackground: {
      dark: colors.brandBlue["400"],
      light: "#EDF1FA",
    },
    header: {
      activeLink: colors.brandBlue["100"],
      backdrop: colors.backdropBackground,
      blockBackground: colors.brandGray["100"],
      featuredLink: colors.brandGray["400"],
    },
    hero: {
      verticalText: colors.brandWhite,
    },
    metrics: {
      name: colors.brandBlue["100"],
    },
    packages: {
      darkBg: colors.brandBlue["300"],
      lightBg: colors.white,
    },
    shadowHeader: colors.shadowHeader,
    toggleButton: colors.brandBlue["100"],
  },
};
