import { ThemeOptions } from "@mui/material";

export const shape: ThemeOptions["shape"] = {
  borderRadius: "5px",
  siteBorder: {
    addons: "0",
  },
  siteBorderRadius: {
    circle: "100%",
    image: "10px",
    lg: "10px",
    sm: "5px",
    toggleButton: "5px",
    xs: "4px",
  },
};
