import { css } from "@emotion/react";
import {
  cssMixins as defaultCssMixins,
  defaultMuiTheme,
} from "@italymondo/core-ui";
import { colors } from "./palette";

export const cssMixins = {
  ...defaultCssMixins,
  diffIconStyle: css`
    > div {
      height: 75px;
    }
    > span {
      height: 75px;
      width: 75px;
      background-color: ${colors.brandBlue["100"]};
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      &:before {
        color: ${colors.brandWhite};
        font-size: 38px;
      }
    }
  `,
  headerMenuWidth: css`
    width: 320px;
  `,
  heroImgLinearGradient: css`
    background: linear-gradient(
      43deg,
      rgba(46, 56, 97, 0.8) 0%,
      rgba(77, 108, 155, 0.75) 84%,
      rgba(84, 121, 170, 0.74) 100%
    );
  `,
  heroLinearGradient: css`
    background: linear-gradient(
      42deg,
      rgba(46, 56, 98, 0.94) 0%,
      rgba(78, 108, 156, 0.94) 85%,
      rgba(84, 120, 169, 0.93) 100%
    );
  `,
  heroSecondaryButtonBackground: css`
    background-color: rgba(233, 91, 120, 0.05);
  `,
  heroSecondaryButtonBackgroundHover: css`
    background-color: rgba(233, 91, 120, 0.1);
  `,
  linearGradientText: css`
    background: -webkit-linear-gradient(
      135deg,
      #2e3862 0%,
      #4e6c9c 58%,
      #5478a9 100%
    );
    background: -moz-linear-gradient(
      135deg,
      #2e3862 0%,
      #4e6c9c 58%,
      #5478a9 100%
    );
    background: linear-gradient(135deg, #2e3862 0%, #4e6c9c 58%, #5478a9 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  `,
  mediaPromotionBackground: css`
    background: linear-gradient(
      42deg,
      rgba(46, 56, 98, 0.94) 0%,
      rgba(78, 108, 156, 0.94) 85%,
      rgba(84, 120, 169, 0.93) 100%
    );
  `,
  processCardShadowHeader: css`
    top: 1.75remrem;
    ${defaultMuiTheme.breakpoints.up("sm")} {
      top: 2.25rem;
    }
  `,
};
