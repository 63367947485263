import React from "react";
import { HubspotProvider } from "@aaronhayes/react-use-hubspot-form";
import { StateProvider } from "@italymondo/core-ui";
import { CssBaseline, GlobalStyles } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { WrapRootElementBrowserArgs } from "gatsby";
import { italyMondoTheme } from "./theme";

export function wrapWithProviders({
  element,
}: WrapRootElementBrowserArgs): React.ReactElement {
  const siteInfo = {
    headerMenuIcon: "IMSmallLogo",
    sectionIcon: "IMSmallLogo",
  };
  return (
    <StateProvider value={siteInfo}>
      <HubspotProvider>
        <ThemeProvider theme={italyMondoTheme}>
          <CssBaseline />
          <GlobalStyles styles={italyMondoTheme.cssMixins.global} />
          {element}
        </ThemeProvider>
      </HubspotProvider>
    </StateProvider>
  );
}
