import React from "react";
import { Context } from "./Context";
import { PpGlobalState, useGlobalState } from "./useGlobalState";

type PpStateProvider = {
  children: React.ReactNode;
  value: PpGlobalState;
};

export function StateProvider({
  children,
  value,
}: PpStateProvider): React.ReactElement {
  const store = useGlobalState(value);

  return <Context.Provider value={store}>{children}</Context.Provider>;
}
