import { defaultMuiTheme } from "@italymondo/core-ui";
import { ThemeOptions } from "@mui/material";
import { coreThemeConstants } from "./coreThemeConstants";

export const typography: ThemeOptions["typography"] = {
  "addons--intro": {
    fontFamily: coreThemeConstants.fontFamilySecondary,
    fontSize: "1.125rem",
    fontWeight: 600,
    [defaultMuiTheme.breakpoints.up("sm")]: {
      fontSize: "1.25rem",
    },
  },
  alertBar: {
    fontFamily: coreThemeConstants.fontFamilySecondary,
    fontSize: "0.875rem",
    fontWeight: 500,
    [defaultMuiTheme.breakpoints.up("sm")]: {
      fontSize: "1rem",
    },
  },
  badge: {
    fontFamily: coreThemeConstants.fontFamilyPrimary,
    fontSize: "0.625rem !important",
    fontWeight: 600,
    letterSpacing: "0.09rem",
    textTransform: "uppercase",
  },
  body1: {
    fontFamily: coreThemeConstants.fontFamilyPrimary,
    fontSize: "0.875rem",
    fontWeight: 400,
    lineHeight: 1.8,
    [defaultMuiTheme.breakpoints.up("sm")]: {
      fontSize: "1rem",
    },
    [defaultMuiTheme.breakpoints.up("xl")]: {
      fontSize: "1.0625rem",
    },
  },
  body2: {
    fontFamily: coreThemeConstants.fontFamilyPrimary,
    fontSize: "0.75rem",
    fontWeight: 200,
    lineHeight: 1.645,
    [defaultMuiTheme.breakpoints.up("sm")]: {
      fontSize: "0.8rem",
    },
    [defaultMuiTheme.breakpoints.up("xl")]: {
      fontSize: "0.875rem",
    },
  },
  button: {
    fontSize: "0.8rem",
    fontWeight: "bold",
    letterSpacing: "0.0825rem",
  },
  caption: {
    fontFamily: coreThemeConstants.fontFamilyPrimary,
    fontSize: "0.625rem",
    fontWeight: 600,
    letterSpacing: "0.094rem",
    textTransform: "uppercase",
    [defaultMuiTheme.breakpoints.up("sm")]: {
      fontSize: "0.75rem",
      letterSpacing: "0.1125rem",
    },
  },
  "caption--sml": {
    fontFamily: coreThemeConstants.fontFamilyPrimary,
    fontSize: "0.55rem",
    fontWeight: 500,
    letterSpacing: "0.0925rem",
    textTransform: "uppercase",
  },
  "caption--xl": {
    fontFamily: coreThemeConstants.fontFamilyPrimary,
    fontSize: "0.825rem",
    fontWeight: 600,
    letterSpacing: "0.15rem",
    textTransform: "uppercase",
    [defaultMuiTheme.breakpoints.up("sm")]: {
      fontSize: "1rem",
    },
  },
  "card--desc": {
    fontFamily: coreThemeConstants.fontFamilyPrimary,
    fontSize: "0.8rem",
    fontWeight: 200,
    lineHeight: 1.645,
    [defaultMuiTheme.breakpoints.up("sm")]: {
      fontSize: "0.825rem",
    },
    [defaultMuiTheme.breakpoints.up("xl")]: {
      fontSize: "0.9rem",
    },
  },
  "diff-title": {
    fontFamily: coreThemeConstants.fontFamilySecondary,
    fontSize: "1.125rem",
    fontWeight: 600,
    [defaultMuiTheme.breakpoints.up("sm")]: {
      fontSize: "1.25rem",
    },
  },
  fontFamily: coreThemeConstants.fontFamilyPrimary,
  fontFamilySecondary: coreThemeConstants.fontFamilySecondary,
  fontWeightBold: 700,
  fontWeightLight: 300,
  fontWeightMedium: 400,
  fontWeightRegular: 400,
  "footer--body": {
    fontFamily: coreThemeConstants.fontFamilyPrimary,
    fontSize: "0.875rem",
    fontWeight: 400,
    lineHeight: 1.8,
    [defaultMuiTheme.breakpoints.up("sm")]: {
      fontSize: "1rem",
    },
    [defaultMuiTheme.breakpoints.up("xl")]: {
      fontSize: "1.0625rem",
    },
  },
  "footer--heading": {
    fontFamily: coreThemeConstants.fontFamilyPrimary,
    fontSize: "0.625rem",
    fontWeight: 500,
    letterSpacing: "0.0925rem",
    textTransform: "uppercase",
  },
  "footer--label": {
    fontFamily: coreThemeConstants.fontFamilyPrimary,
    fontSize: "0.425rem",
    fontWeight: 600,
    letterSpacing: "0.045rem",
    textTransform: "uppercase",
    [defaultMuiTheme.breakpoints.up("sm")]: {
      fontSize: "0.5rem",
    },
  },
  "footer--legal": {
    fontFamily: coreThemeConstants.fontFamilyPrimary,
    fontSize: "0.625rem",
    fontWeight: 600,
    letterSpacing: "0.09375rem",
    textTransform: "uppercase",
    [defaultMuiTheme.breakpoints.up("sm")]: {
      fontSize: "0.625rem",
    },
  },
  h1: {
    fontFamily: coreThemeConstants.fontFamilySecondary,
    fontSize: "3rem",
    fontWeight: 500,
    [defaultMuiTheme.breakpoints.up("sm")]: {
      fontSize: "4rem",
    },
    [defaultMuiTheme.breakpoints.up("md")]: {
      fontSize: "4.25rem",
    },
    [defaultMuiTheme.breakpoints.up("xl")]: {
      fontSize: "5.5rem",
    },
  },
  h2: {
    fontFamily: coreThemeConstants.fontFamilySecondary,
    fontSize: "2rem",
    fontWeight: 500,
    [defaultMuiTheme.breakpoints.up("sm")]: {
      fontSize: "2.5625rem",
    },
  },
  "h2--sml": {
    fontFamily: coreThemeConstants.fontFamilySecondary,
    fontSize: "2rem",
    fontWeight: 500,
    [defaultMuiTheme.breakpoints.up("sm")]: {
      fontSize: "2.125rem",
    },
  },
  h3: {
    fontFamily: coreThemeConstants.fontFamilySecondary,
    fontSize: "1.5rem",
    fontWeight: 500,
    [defaultMuiTheme.breakpoints.up("sm")]: {
      fontSize: "1.8125rem",
    },
  },
  h4: {
    fontFamily: coreThemeConstants.fontFamilySecondary,
    fontSize: "1.125rem",
    fontWeight: 600,
    [defaultMuiTheme.breakpoints.up("sm")]: {
      fontSize: "1.25rem",
    },
  },
  h5: {
    fontFamily: coreThemeConstants.fontFamilySecondary,
    fontSize: "1rem",
    fontWeight: 600,
  },
  h6: {
    fontFamily: coreThemeConstants.fontFamilyPrimary,
    fontSize: "0.875rem",
    fontWeight: 700,
    letterSpacing: "0.2rem",
    [defaultMuiTheme.breakpoints.up("sm")]: {
      fontSize: "1.125rem",
    },
    [defaultMuiTheme.breakpoints.up("xl")]: {
      fontSize: "1.3125rem",
    },
  },
  "toggle-btn--label": {
    fontFamily: coreThemeConstants.fontFamilyPrimary,
    fontSize: "0.625rem",
    fontWeight: 600,
    letterSpacing: "0.094rem",
    textTransform: "uppercase",
    [defaultMuiTheme.breakpoints.up("sm")]: {
      fontSize: "0.75rem",
      letterSpacing: "0.1125rem",
    },
  },
  "hero-desc": {
    fontFamily: coreThemeConstants.fontFamilyPrimary,
    fontSize: "1rem",
    fontWeight: 400,
    lineHeight: 1.8,
    [defaultMuiTheme.breakpoints.up("sm")]: {
      fontSize: "1.275rem",
    },
    [defaultMuiTheme.breakpoints.up("md")]: {
      fontSize: "1.625rem",
    },
    [defaultMuiTheme.breakpoints.up("xl")]: {
      fontSize: "1.8rem",
    },
  },
  input: {
    fontFamily: coreThemeConstants.fontFamilyPrimary,
    fontSize: "0.875rem",
    fontWeight: 400,
    lineHeight: 1.8,
    [defaultMuiTheme.breakpoints.up("sm")]: {
      fontSize: "1rem",
    },
    [defaultMuiTheme.breakpoints.up("xl")]: {
      fontSize: "1.0625rem",
    },
  },
  "input--label": {
    fontFamily: coreThemeConstants.fontFamilyPrimary,
    fontSize: "0.875rem !important",
    fontWeight: 200,
    lineHeight: 1.645,
  },
  "input--placeholder": {
    fontFamily: coreThemeConstants.fontFamilyPrimary,
    fontSize: "0.75rem",
    fontWeight: 200,
    lineHeight: 1.645,
    [defaultMuiTheme.breakpoints.up("sm")]: {
      fontSize: "0.8rem",
    },
    [defaultMuiTheme.breakpoints.up("xl")]: {
      fontSize: "0.875rem",
    },
  },
  intro: {
    fontFamily: coreThemeConstants.fontFamilySecondary,
    fontSize: "1.5rem",
    fontWeight: 400,
    lineHeight: 1.45,
    [defaultMuiTheme.breakpoints.up("xl")]: {
      fontSize: "1.8125rem",
    },
  },
  label: {
    fontFamily: coreThemeConstants.fontFamilyPrimary,
    fontSize: "0.625rem",
    fontWeight: 600,
    letterSpacing: "0.09375rem",
    textTransform: "uppercase",
    [defaultMuiTheme.breakpoints.up("sm")]: {
      fontSize: "0.625rem",
    },
  },
  "label--sml": {
    fontFamily: coreThemeConstants.fontFamilyPrimary,
    fontSize: "0.5rem",
    fontWeight: 600,
    letterSpacing: "0.09375rem",
    textTransform: "uppercase",
    [defaultMuiTheme.breakpoints.up("sm")]: {
      fontSize: "0.575rem",
    },
  },
  locationHeading: {
    fontFamily: coreThemeConstants.fontFamilySecondary,
    fontSize: "1.125rem",
    fontWeight: 500,
    lineHeight: 1.4,
    [defaultMuiTheme.breakpoints.up("sm")]: {
      fontSize: "1.425rem",
    },
  },
  navItem: {
    fontFamily: coreThemeConstants.fontFamilyPrimary,
    fontSize: "1.25rem",
    fontWeight: 400,
    lineHeight: 1.8,
    [defaultMuiTheme.breakpoints.up("sm")]: {
      fontSize: "1rem",
    },
    [defaultMuiTheme.breakpoints.up("xl")]: {
      fontSize: "1.0625rem",
    },
  },
  overline: {
    lineHeight: coreThemeConstants.lineHeight,
  },
  "processCard--number": {
    fontFamily: coreThemeConstants.fontFamilySecondary,
    fontSize: "4.25rem",
    lineHeight: 1,
    [defaultMuiTheme.breakpoints.up("sm")]: {
      fontSize: "6.875rem",
    },
  },
  "section--name": {
    fontFamily: coreThemeConstants.fontFamilyPrimary,
    fontSize: "0.75rem",
    fontWeight: 600,
    letterSpacing: "0.094rem",
    textTransform: "uppercase",
    [defaultMuiTheme.breakpoints.up("sm")]: {
      fontSize: "0.825rem",
      letterSpacing: "0.1125rem",
    },
  },
  "section--name_intro": {
    fontFamily: coreThemeConstants.fontFamilyPrimary,
    fontSize: "2rem",
    fontWeight: 600,
    [defaultMuiTheme.breakpoints.up("sm")]: {
      fontSize: "2.5rem",
      letterSpacing: "0.1125rem",
    },
    letterSpacing: "0.094rem",
    lineHeight: 1,
    textTransform: "uppercase",
  },
  select: {
    fontFamily: coreThemeConstants.fontFamilyPrimary,
    fontSize: "0.625rem !important",
    fontWeight: 600,
    letterSpacing: "0.09rem",
  },
  "testimonial--lg": {
    fontFamily: coreThemeConstants.fontFamilySecondary,
    fontSize: "1.125rem",
    fontWeight: 400,
    lineHeight: 1.54,
    [defaultMuiTheme.breakpoints.up("md")]: {
      fontSize: "1.25rem",
    },
    [defaultMuiTheme.breakpoints.up("xl")]: {
      fontSize: "1.35rem",
    },
  },
  "testimonial--sm": {
    fontFamily: coreThemeConstants.fontFamilySecondary,
    fontSize: "1rem",
    fontWeight: 500,
    lineHeight: 1.65,
    [defaultMuiTheme.breakpoints.up("md")]: {
      fontSize: "1.125rem",
    },
  },
  "toggle-btn--title": {
    fontFamily: coreThemeConstants.fontFamilyPrimary,
    fontSize: "0.725rem",
    fontWeight: 500,
    letterSpacing: "0.0925rem",
    textTransform: "capitalize",
    wordBreak: "break-word",
    [defaultMuiTheme.breakpoints.up("lg")]: {
      fontFamily: coreThemeConstants.fontFamilySecondary,
      fontSize: "1.625rem",
      fontWeight: 700,
      lineHeight: 1,
      textTransform: "none",
    },
  },
  "vertical-text": {
    fontFamily: coreThemeConstants.fontFamilyPrimary,
    fontSize: "0.625rem",
    fontWeight: 600,
    letterSpacing: "0.094rem",
    textTransform: "uppercase",
    [defaultMuiTheme.breakpoints.up("xl")]: {
      fontSize: "0.75rem",
      letterSpacing: "0.1125rem",
    },
  },
};
