import { useEffect, useState } from "react";

type TpSiteInfo = {
  headerMenuIcon: string;
  sectionIcon: string;
};

export type TpGlobalState = {
  hideAlertBar: () => void;
  showAlertBar: boolean;
  siteInfo: TpSiteInfo | null;
};

export type PpGlobalState = {
  headerMenuIcon: string;
  sectionIcon: string;
};

export function useGlobalState(sInfo: PpGlobalState): TpGlobalState {
  const [showAlertBar, setShowAlertBar] = useState<boolean>(false);
  const [siteInfo, setSiteInfo] = useState<TpSiteInfo | null>(null);

  useEffect(() => {
    if (typeof window !== "undefined" && window) {
      const alertBarObject = window.sessionStorage.getItem(
        "alert-bar-dismissed"
      );
      if (!alertBarObject) {
        setShowAlertBar(true);
      }
    }
  }, []);

  useEffect(() => {
    setSiteInfo(sInfo);
  }, [sInfo]);

  function hideAlertBar(): void {
    setShowAlertBar(false);
    if (typeof window !== "undefined" && window) {
      window.sessionStorage.setItem(
        "alert-bar-dismissed",
        JSON.stringify(true)
      );
    }
  }

  return { hideAlertBar, showAlertBar, siteInfo };
}
