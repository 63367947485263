export const coreThemeConstants = {
  fontFamilyPrimary: [
    "acumin-pro",
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    '"Helvetica Neue"',
    "Roboto",
    "Arial",
    "sans-serif",
  ].join(","),
  fontFamilySecondary: [
    "freight-text-pro",
    "Times",
    '"Times New Roman"',
    "serif",
  ].join(","),
  lineHeight: 1.5,
};
