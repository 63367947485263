import { createTheme } from "@mui/material";
import { components } from "./components";
import { cssMixins } from "./cssMixins";
import { palette } from "./palette";
import { shadows } from "./shadows";
import { shape } from "./shape";
import { typography } from "./typography";

export const italyMondoTheme = createTheme({
  breakpoints: {
    values: {
      lg: 1280,
      md: 960,
      sm: 600,
      xl: 1920,
      xs: 0,
    },
  },
  components,
  cssMixins,
  palette,
  shadows,
  shape,
  themeName: "italyMondo!",
  typography,
});
