/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { defaultMuiTheme } from "@italymondo/core-ui";
import { ThemeOptions } from "@mui/material";
import { cssMixins } from "./cssMixins";
import { colors } from "./palette";
import { shadows } from "./shadows";
import { shape } from "./shape";
import { typography } from "./typography";

// See: https://next.material-ui.com/customization/theme-components/

export const components: ThemeOptions["components"] = {
  MuiAccordionSummary: {
    styleOverrides: {
      root: {
        textTransform: "none",
      },
    },
  },
  MuiButton: {
    defaultProps: {
      disableElevation: true,
      disableFocusRipple: true,
    },
    styleOverrides: {
      contained: {
        padding: "10px 20px",
      },
      outlined: {
        padding: "9px 20px",
      },
      outlinedSecondary: {
        borderColor: `${colors.brandRed}`,
      },
      root: {
        borderRadius: 5,
        padding: "9px 20px",
        textTransform: "capitalize",
      },
    },
  },
  MuiButtonBase: {
    defaultProps: {
      type: "button",
    },
    styleOverrides: {
      root: {
        textTransform: "capitalize",
      },
    },
  },
  MuiCard: {
    styleOverrides: {
      root: {
        borderRadius: shape?.siteBorderRadius.lg,
        boxShadow: shadows[1],
        maxWidth: 450,
        position: "relative",
      },
    },
  },
  MuiCardActions: {
    styleOverrides: {
      root: {
        padding: defaultMuiTheme.spacing(0, 2, 3, 2),
      },
    },
  },
  MuiCheckbox: {
    styleOverrides: {
      root: {
        "&.Mui-checked:hover": {
          background: "none !important",
        },
        "&:hover": {
          background: "none !important",
        },
      },
    },
  },
  MuiContainer: {
    styleOverrides: {
      maxWidthLg: {
        maxWidth: `${cssMixins.containerMaxWidth} !important`,
      },
    },
  },
  MuiFilledInput: {
    styleOverrides: {
      root: {
        borderRadius: "10px",
      },
    },
  },
  MuiFormControl: {
    styleOverrides: {
      root: {
        "&.darkMode": {
          ".MuiSelect-root": {
            "&.Mui-focused": {
              ".MuiSelect-select": {
                color: `${colors.brandBlue["100"]}`,
              },
            },
          },
        },
        "&:not(.darkMode)": {
          ".MuiSelect-root": {
            "&.Mui-focused": {
              ".MuiSelect-select": {
                backgroundColor: `${colors.brandBlue["100"]}`,
              },
            },
          },
        },
        "label + .MuiInputBase-root": {
          marginTop: `${defaultMuiTheme.spacing(2.5)} !important`,
        },
      },
    },
  },
  MuiFormLabel: {
    styleOverrides: {
      asterisk: {
        display: "none",
      },
    },
  },
  MuiInput: {
    styleOverrides: {
      input: {},
      root: {
        "&.Mui-focused": {
          "input.MuiInput-input, textarea.MuiInput-input": {
            "&:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus, &:-webkit-autofill:active":
              {
                border: `2px solid ${colors.brandBlue["100"]}`,
              },
            "-moz-appearance": "none",
            "-webkit-appearance": "none",
            appearance: "none",
            backgroundColor: colors.white,
            border: `2px solid ${colors.brandBlue["100"]}`,
          },
        },
        "input.MuiInput-input": {
          height: 23,
        },
        "input.MuiInput-input, textarea.MuiInput-input": {
          "&:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus, &:-webkit-autofill:active":
            {
              border: `2px solid ${colors.white}`,
              WebkitBoxShadow: "0 0 0 30px white inset !important",
              WebkitTextFillColor: colors.brandBlue["400"],
            },
          "-moz-appearance": "none",
          "-webkit-appearance": "none",
          appearance: "none",
          backgroundColor: colors.white,
          border: "2px solid transparent",
          borderRadius: shape?.siteBorderRadius?.lg,
          color: colors.brandBlue["400"],
          padding: defaultMuiTheme.spacing(1, 2),
          transition: defaultMuiTheme.transitions.create("border", {
            duration: defaultMuiTheme.transitions.duration.shorter,
          }),
          ...typography.input,
          "&.Mui-focused": {
            backgroundColor: colors.white,
          },
          [defaultMuiTheme.breakpoints.down("sm")]: {
            borderRadius: shape?.siteBorderRadius?.sm,
          },
          "&::-ms-input-placeholder": {
            /* Internet Explorer 10-11 */ ...typography["input--placeholder"],
            color: colors.brandBlue["400"],
            opacity: 0.6,
            transform: "translate3d(0px, -0.075rem, 0px)",
          },
          "&::-webkit-input-placeholder": {
            /* Edge */ ...typography["input--placeholder"],
            color: colors.brandBlue["400"],
            opacity: 0.6,
            transform: "translate3d(0px, -0.075rem, 0px)",
          },
          "&::placeholder": {
            ...typography["input--placeholder"],
            color: colors.brandBlue["400"],
            opacity: 0.6,
            transform: "translate3d(0px, -0.075rem, 0px)",
          },
        },
        "textarea.MuiInput-input": {
          "&::-ms-input-placeholder": {
            paddingTop: 5,
          },
          "&::-webkit-input-placeholder": {
            paddingTop: 5,
          },
          "&::placeholder": {
            paddingTop: 5,
          },
        },
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: {
        ...typography["input--label"],
        "&.Mui-disabled": {
          display: "none",
        },
        "&.Mui-focused": {
          color: colors.brandBlue["100"],
          display: "block !important",
        },
        display: "block",
        marginLeft: defaultMuiTheme.spacing(1.5),
        transform: "translate(0, -1.5px) scale(0.75)",
      },
    },
  },
  MuiLink: {
    styleOverrides: {
      root: {
        "&:hover": {
          textDecoration: "none",
        },
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        color: colors.brandBlue["400"],
        ...typography.select,
        "&.Mui-focusVisible": {
          backgroundColor: "transparent",
        },
        "&.Mui-selected": {
          "&:hover": {
            backgroundColor: "rgba(133, 198, 255, 0.35)",
          },
          backgroundColor: "rgba(133, 198, 255, 0.3)",
        },
        "&:hover": {
          backgroundColor: "rgba(133, 198, 255, 0.15)",
        },
        textTransform: "capitalize",
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      notchedOutline: {
        borderWidth: "0px !important",
      },
    },
  },
  // TO DO: fix typing error (edit types.d.ts file)
  MuiRating: {
    styleOverrides: {
      iconEmpty: {
        color: colors.brandBlue["400"],
        // opacity: 0.2,
      },
      iconFilled: {
        color: colors.brandBlue["400"],
      },
    },
  },
  MuiSelect: {
    styleOverrides: {
      icon: {
        color: colors.brandWhite,
        marginRight: 8,
      },
      root: {},
      select: {
        alignItems: "center",
        display: "flex",
        height: 23,
        ...typography.select,
        "&:focus": {
          backgroundColor: `${colors.brandBlue["400"]}`,
          borderRadius: shape?.siteBorderRadius?.lg,
          [defaultMuiTheme.breakpoints.down("sm")]: {
            borderRadius: shape?.siteBorderRadius?.sm,
          },
        },
        backgroundColor: colors.brandBlue["400"],
        border: "2px solid transparent",
        borderRadius: shape?.siteBorderRadius?.lg,
        color: colors.brandWhite,
        padding: defaultMuiTheme.spacing(1, 2),
        [defaultMuiTheme.breakpoints.down("sm")]: {
          borderRadius: shape?.siteBorderRadius?.sm,
        },
        paddingRight: `${defaultMuiTheme.spacing(6)} !important`,
        textTransform: "capitalize",
        transition: defaultMuiTheme.transitions.create("background-color", {
          duration: defaultMuiTheme.transitions.duration.shorter,
        }),
      },
    },
  },
  MuiSlider: {
    styleOverrides: {
      rail: {
        borderRadius: shape?.siteBorderRadius.lg,
        height: 8,
      },
      thumb: {
        "&.Mui-active": {
          boxShadow: "none",
        },
        "&:not(.MuiSlider-active)": {
          transition: defaultMuiTheme.transitions.create("left", {
            duration: defaultMuiTheme.transitions.duration.shorter,
            easing: defaultMuiTheme.transitions.easing.easeIn,
          }),
        },
        borderRadius: shape?.siteBorderRadius.lg,
        height: 8,
        marginTop: 0,
      },
      track: {
        transition: defaultMuiTheme.transitions.create("width", {
          duration: defaultMuiTheme.transitions.duration.shorter,
          easing: defaultMuiTheme.transitions.easing.easeIn,
        }),
      },
    },
  },
  MuiToggleButton: {
    defaultProps: {
      disableFocusRipple: true,
      disableRipple: true,
    },
    styleOverrides: {
      root: {
        "&.Mui-selected": {
          "&:hover": {
            backgroundColor: `${colors.white} !important`,
          },
          backgroundColor: colors.white,
          color: `${colors.white} !important`,
        },
        backgroundColor: colors.white,
        border: "none !important",
        borderRadius: "5px !important",
        color: colors.brandBlue["400"],
        marginLeft: "0 !important",
        transition: defaultMuiTheme.transitions.create("all", {
          duration: defaultMuiTheme.transitions.duration.shorter,
        }),
        [defaultMuiTheme.breakpoints.up("md")]: {
          "&:hover:not(.Mui-selected)": {
            backgroundColor: `${colors.white} !important`,
          },
          textTransform: "none !important",
        },
      },
    },
  },
  MuiToggleButtonGroup: {
    styleOverrides: {
      root: {
        backgroundColor: colors.white,
        boxShadow: shadows[1],
      },
    },
  },
  MuiTooltip: {
    styleOverrides: {
      arrow: {
        color: colors.brandBlue["100"],
      },
      popper: {
        zIndex: 1090,
      },
      tooltip: {
        backgroundColor: colors.brandBlue["100"],
        padding: defaultMuiTheme.spacing(1),
        textAlign: "center",
      },
    },
  },
};
