// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-about-index-tsx": () => import("./../../../src/templates/about/index.tsx" /* webpackChunkName: "component---src-templates-about-index-tsx" */),
  "component---src-templates-blogs-index-tsx": () => import("./../../../src/templates/blogs/index.tsx" /* webpackChunkName: "component---src-templates-blogs-index-tsx" */),
  "component---src-templates-career-index-index-tsx": () => import("./../../../src/templates/careerIndex/index.tsx" /* webpackChunkName: "component---src-templates-career-index-index-tsx" */),
  "component---src-templates-career-index-tsx": () => import("./../../../src/templates/career/index.tsx" /* webpackChunkName: "component---src-templates-career-index-tsx" */),
  "component---src-templates-careers-apply-index-tsx": () => import("./../../../src/templates/careersApply/index.tsx" /* webpackChunkName: "component---src-templates-careers-apply-index-tsx" */),
  "component---src-templates-confirmation-index-tsx": () => import("./../../../src/templates/confirmation/index.tsx" /* webpackChunkName: "component---src-templates-confirmation-index-tsx" */),
  "component---src-templates-founder-index-tsx": () => import("./../../../src/templates/founder/index.tsx" /* webpackChunkName: "component---src-templates-founder-index-tsx" */),
  "component---src-templates-getstarted-index-tsx": () => import("./../../../src/templates/getstarted/index.tsx" /* webpackChunkName: "component---src-templates-getstarted-index-tsx" */),
  "component---src-templates-legal-index-index-tsx": () => import("./../../../src/templates/legalIndex/index.tsx" /* webpackChunkName: "component---src-templates-legal-index-index-tsx" */),
  "component---src-templates-legal-index-tsx": () => import("./../../../src/templates/legal/index.tsx" /* webpackChunkName: "component---src-templates-legal-index-tsx" */),
  "component---src-templates-reviews-index-tsx": () => import("./../../../src/templates/reviews/index.tsx" /* webpackChunkName: "component---src-templates-reviews-index-tsx" */),
  "component---src-templates-service-index-tsx": () => import("./../../../src/templates/service/index.tsx" /* webpackChunkName: "component---src-templates-service-index-tsx" */),
  "component---src-templates-services-index-tsx": () => import("./../../../src/templates/services/index.tsx" /* webpackChunkName: "component---src-templates-services-index-tsx" */)
}

